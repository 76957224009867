import http from '@/service/http.js';

const state = {
  acao: null,
  loteAtivo: null,
  acaoAtiva: null,
  lanceLoading: false,
  lanceError: null,
};

const getters = {
  getAcao: (state) => state.acao,
  getAcaoLoteAtivo: (state) => {
    if (!state.acao || !state.loteAtivo || !state.acaoAtiva) return;
    return state.acao[state.loteAtivo] ? state.acao[state.loteAtivo].status : null;
  },
  getLancePresencialLoading: (state) => state.lanceLoading,
  getLancePresencialError: (state) => state.lanceError,
};

const mutations = {
  setAcao: (state, value) => {
    if (!value) return;

    if (state.acao == null) state.acao = {};
    state.acao[`${value.id}`] = { status: value.status };
  },
  setLoteAtivo: (state, value) => {
    if (!value) return;
    state.loteAtivo = value;
  },
  setAcaoAtiva: (state, value) => (value ? (state.acaoAtiva = value) : null),
  SET_LANCE_PRESENCIAL_LOADING: (state, value) => (value ? (state.lanceLoading = value) : (state.lanceLoading = false)),
  SET_LANCE_PRESENCIAL_ERROR: (state, value) => (value ? (state.lanceError = value) : (state.lanceError = false)),
};

const actions = {
  async iniciar(data, loteId) {
    return new Promise((resolve, reject) => {
      http
        .post(`PainelControle/IniciarFechamento/${loteId}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },
  async parar(data, loteId) {
    return new Promise((resolve, reject) => {
      http
        .post(`PainelControle/PararFechamento/${loteId}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },
  async encerrar(data, loteId) {
    return new Promise((resolve, reject) => {
      http
        .post(`PainelControle/EncerrarLote/${loteId}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },
  async notificarPregao(data, dados) {
    return new Promise((resolve, reject) => {
      http
        .post(`PainelControle/NotificarPregao`, { loteId: dados.loteId, douLhe: dados.doulhe })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },
  async homologarLote(data, dados) {
    return new Promise((resolve, reject) => {
      http
        .post(`PainelControle/HomologarLote`, { loteId: dados.loteId })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },
  async encerrarPresencial(data, dados) {
    return new Promise((resolve, reject) => {
      http
        .post(`PainelControle/EncerrarLotePresencial`, { loteId: dados.loteId, cartela: dados.cartela })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },
  lancePresencial({ commit }, value) {
    commit('SET_LANCE_PRESENCIAL_LOADING', true);

    return http({
      url: `PainelControle/LancePresencial/${value.loteId}`,
      method: 'POST',
      data: value,
    })
      .then(() => {
        commit('SET_LANCE_PRESENCIAL_ERROR');
        commit('SET_LANCE_PRESENCIAL_LOADING');
      })
      .catch((error) => {
        const result = [];
        const errors = error.response.data.Errors;
        for (var key in errors) {
          result.push(errors[key]);
        }
        commit('SET_LANCE_PRESENCIAL_ERROR', result);
        commit('SET_LANCE_PRESENCIAL_LOADING');
      });
  },
};

export default {
  state,
  mutations,
  getters,
  actions,
};
