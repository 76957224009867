import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

import pt from 'vuetify/lib/locale/pt';
import iconBid from '../views/components/icon-bid.vue';
import iconId from '../views/components/icon-id.vue';

export default new Vuetify({
  lang: {
    locales: { pt },
    current: 'pt',
  },
  icons: {
    iconFont: 'mdi',
    values: {
      iconBid: {
        component: iconBid,
      },
      iconId: {
        component: iconId,
      },
    },
  },
});
